import { css, Global } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Header } from '../components';
import { Spin } from 'antd';
import brandJump from '../images/brand/mascot-jump1.png';
import { FC } from '../interfaces';
import configs from '../configs';
import { useTitle } from '../hooks';
import apis from '../apis';

/** 首页的属性接口 */
interface IndexProps {}
/**
 * 首页
 */
const Index: FC<IndexProps> = () => {
  const { formatMessage } = useIntl(); // i18n
  useTitle({ suffix: configs.siteSlogan || formatMessage({ id: 'site.slogan' }) }); // 设置标题
  const [homepageHtml, setHomepageHtml] = useState<string>();
  const [homepageCss, setHomepageCss] = useState<string>();

  useEffect(() => {
    apis
      .getHomepage({})
      .then((res) => {
        setHomepageHtml(res.data.html);
        setHomepageCss(res.data.css);
      })
      .catch((err) => {
        setHomepageHtml('');
        setHomepageCss('');
      });
  }, []);
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        .Index__Title {
          flex: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-height: 300px;
          }
        }
        .Index__Footer {
          height: 50px;
          text-align: center;
          a {
            font-size: 16px;
          }
        }
        .Index_Homepage__Footer {
          position: fixed;
          left: 0px;
          right: 0px;
          bottom: 0px;
          text-align: center;
          p {
            margin: 0px;
            padding: 0px;
            font-size: 16px;
            line-height: 1.5em;
          }
          code {
            color: rgb(255, 101, 124);
          }
          .tipBox {
            background: rgba(250, 173, 20, 0.5);
            width: 96%;
            margin: 0px auto;
            border-radius: 6px 6px 0px 0px;
            padding: 4px;
          }
        }
      `}
    >
      <Global
        styles={css`
          #root {
            width: 100%;
            height: 100%;
          }
        `}
      />
      <Header></Header>
      {homepageHtml === undefined ? (
        <Spin />
      ) : homepageHtml === '' ? (
          <div className="Index__Title">
            <img src={brandJump} alt="Mascot" />
          </div>
      ) : (
        <>
          <Global
            styles={css`
              ${homepageCss}
            `}
          />
          <div
            id="homepage"
            className="Index_Homepage"
            dangerouslySetInnerHTML={{ __html: homepageHtml }}
          />
        </>
      )}
      <div className="Index_Homepage__Footer">
        <div className="tipBox">
          <p>本站反馈群 <code>451050931</code>。您可以通过 <a href="https://afdian.net/a/dr490n" target="_blank">爱发电平台</a> <a href="https://www.patreon.com/dr490n" target="_blank">Patreon</a> 或者群内私聊群主等方式支持我们继续运营。</p>
        </div>
      </div>
      {configs.miitBeiAn ? (
        <div className="Index__Footer">
          <a href="https://beian.miit.gov.cn/" target="_blank">{configs.miitBeiAn}</a>
        </div>
      ) : ''}
    </div>
  );
};
export default Index;
